<nav #navbar class="navbar navbar-expand-lg navbar-transparent">
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <button
          mat-raised-button
          (click)="minimizeSidebar()"
          class="btn btn-just-icon btn-white btn-fab btn-round">
          <i class="material-icons text_align-center visible-on-sidebar-regular"
            >more_vert</i
          >
          <i
            class="material-icons design_bullet-list-67 visible-on-sidebar-mini"
            >view_list</i
          >
        </button>
      </div>
      <div class="breadcrumbs">
        <a class="navbar-brand" href="{{ getPath() }}">{{
          profile.team.name
        }}</a>
        <xng-breadcrumb [separator]="iconTemplate"></xng-breadcrumb>
        <ng-template #iconTemplate>
          <mat-icon>chevron_right</mat-icon>
        </ng-template>
      </div>
    </div>

    <button
      mat-button
      class="navbar-toggler btn-no-ripple"
      type="button"
      (click)="sidebarToggle()">
      <span class="sr-only">Toggle navigation</span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navigation">
      <ul class="navbar-nav">
       

        <li
          class="nav-item dropdown"
          *ngIf="profile.role.endsWith('admin') && teams$ | async as teams">
          <a
            class="nav-link"
            href="#"
            id="navbarTeams"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false">
            <i class="material-icons">people</i> Teams
          </a>

          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="navbarDropdownMenuLink">
            <a
              class="dropdown-item"
              *ngFor="let team of teams"
              (click)="onTeamClick(team)"
              >{{ team.name }}</a
            >
          </div>
        </li>
        <!--
        <li class="nav-item dropdown">
          <a
            class="nav-link"
            href="#pablo"
            id="navbarDropdownMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false">
            <i class="material-icons">notifications</i>
            <span class="notification">5</span>
            <p>
              <span class="d-lg-none d-md-block">Some Actions</span>
            </p>
          </a>
          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item" href="#"
              >Mike John responded to your email</a
            >
            <a class="dropdown-item" href="#">You have 5 new tasks</a>
            <a class="dropdown-item" href="#">You're now friend with Andrew</a>
            <a class="dropdown-item" href="#">Another Notification</a>
            <a class="dropdown-item" href="#">Another One</a>
          </div>
        </li>
        -->
        <li class="nav-item">
          <a class="nav-link" href="#" (click)="onLogout()">
            <i class="material-icons">fingerprint</i> Logout
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
