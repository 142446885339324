import { Injectable } from '@angular/core';
import { environment } from '../../..//environments/environment';
declare const Pusher: any;

@Injectable()
export class PusherService {
  private pusher: any;

  constructor() {
    this.pusher = new Pusher(environment.pusherKey, {
      cluster: environment.pusherCluster,
      encrypted: true,
    });
  }
  public init(channel:string) {
    return this.pusher.subscribe(channel);
  }

  public disconnect() {
    this.pusher.disconnect();
  }
}
