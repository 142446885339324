import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { get, has } from 'lodash-es';
import { NGXLogger } from 'ngx-logger';
import { lastValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { FeatureConfig } from '../interfaces/feature.interface';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsService {
  config: FeatureConfig = null;
  configUrl = environment.featureConfig; // <-- URL for getting the config

  constructor(private http: HttpClient, private logger: NGXLogger) {}

  /**
   * We convert it to promise so that this function can
   * be called by the APP_INITIALIZER
   */
  async loadConfig() {
    const source$ = this.http
      .get<FeatureConfig>(this.configUrl)
      .pipe(tap(data => (this.config = data)));
    const final = await lastValueFrom(source$);
    this.logger.info('loadConfig', final);
    return final;
  }

  isEnabled(key: string) {
    //this.logger.info("isFeatureEnabled", this.config)
    if (this.config && has(this.config, key)) {
      return get(this.config, key, false);
    }
    return false;
  }
}
