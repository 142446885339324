import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-my-app',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  private _router: Subscription;

  constructor(
    private router: Router,
    public auth: AuthService,
    private logger: NGXLogger
  ) {}

  ngOnInit() {
    this.auth.appState$.subscribe(appState => {
      this.logger.info('appState', appState);
    });
    this._router = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const body = document.getElementsByTagName('body')[0];
        const modalBackdrop =
          document.getElementsByClassName('modal-backdrop')[0];
        if (body.classList.contains('modal-open')) {
          body.classList.remove('modal-open');
          modalBackdrop.remove();
        }
      });
  }
}
