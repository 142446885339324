import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PagedResult } from '../interfaces/paged-results.interface';
import { Swimmer } from '../interfaces/swimmer.interface';
import { Recipient } from '../interfaces/recipient.interface';
import { History } from '../interfaces/history.interface';
import { Time } from '../interfaces/time.interface';
import { StrokeScore } from '../interfaces/stroke-score.interface';
import { TimeStandard } from '../interfaces/time-standard.interface';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root',
})
export class SwimmerService {
  constructor(private http: HttpClient, private logger: NGXLogger) {}

  private _serviceUrl: string = environment.serviceUrl;

  getSwimmers(
    keyword: string,
    nextSet: number
  ): Observable<HttpResponse<PagedResult<Swimmer>>> {
    const apiURL = `${this._serviceUrl}/swimmers/search/?keyword=${keyword}&nextSet=${nextSet}`;
    this.logger.info('service url: ', apiURL);
    return this.http.get<PagedResult<Swimmer>>(apiURL, { observe: 'response' });
  }

  getFromIds(code: string, ids: string[]): Observable<HttpResponse<Swimmer[]>> {
    const apiURL = `${this._serviceUrl}/swimmers/team/${code}/from/ids`;
    this.logger.info('service url: ', apiURL);
    return this.http.post<Swimmer[]>(apiURL, ids, { observe: 'response' });
  }

  getSwimmersOnTeam(
    code: string,
    keyword: string,
    onlyInActive: boolean,
    nextSet: number
  ): Observable<HttpResponse<PagedResult<Swimmer>>> {
    const onlyInactive = onlyInActive ? 'onlyInActive=true&' : '';
    const apiURL = `${this._serviceUrl}/swimmers/team/${code}/search/?${onlyInactive}keyword=${keyword}&nextSet=${nextSet}`;
    this.logger.info('service url: ', apiURL);
    return this.http.get<PagedResult<Swimmer>>(apiURL, { observe: 'response' });
  }


  getSwimmersOnTeamForGroup(
    code: string,
    group: string,
    keyword?: string,
    onlyInActive = false,
  ): Observable<HttpResponse<Swimmer[]>> {
    const onlyInactive = onlyInActive ? 'onlyInActive=true&' : '';
    const apiURL = `${this._serviceUrl}/swimmers/team/${code}/group/${group}`;
    this.logger.info('service url: ', apiURL);
    return this.http.get<Swimmer[]>(apiURL, { observe: 'response' });
  }


  getSwimmersIdsOnTeam(
    code: string
  ): Observable<HttpResponse<{ [id: string]: string }>> {
    const apiURL = `${this._serviceUrl}/swimmers/team/${code}/names`;
    this.logger.info('service url: ', apiURL);
    return this.http.get<{ [id: string]: string }>(apiURL, {
      observe: 'response',
    });
  }
  getRecipients(code: string): Observable<HttpResponse<Recipient[]>> {
    const apiURL = `${this._serviceUrl}/swimmers/team/${code}/recipients`;
    this.logger.info('service url: ', apiURL);
    return this.http.get<Recipient[]>(apiURL, {
      observe: 'response',
    });
  }

  getAllSwimmersForTeam(code: string): Observable<HttpResponse<Swimmer[]>> {
    const apiURL = `${this._serviceUrl}/swimmers/team/${code}`;
    this.logger.info('service url: ', apiURL);
    return this.http.get<Swimmer[]>(apiURL, { observe: 'response' });
  }

  getSwimmer(id: string): Observable<HttpResponse<Swimmer>> {
    const apiURL = `${this._serviceUrl}/swimmers/swimmer/${id}`;
    this.logger.info('service url: ', apiURL);
    return this.http.get<Swimmer>(apiURL, { observe: 'response' });
  }

  updateSwimmer(
    id: string,
    props: { [name: string]: string }
  ): Observable<HttpResponse<void>> {
    const apiURL = `${this._serviceUrl}/swimmers/swimmer/${id}/update`;
    this.logger.info('service url: ', apiURL);
    return this.http.post<void>(apiURL, props, { observe: 'response' });
  }

  createSwimmer(payload: any): Observable<HttpResponse<string>> {
    const apiURL = `${this._serviceUrl}/swimmers/`;
    this.logger.info('service url: ', apiURL);
    return this.http.post<string>(apiURL, payload, { observe: 'response' });
  }

  toggleSwimmer(id: string, isActive: boolean): Observable<HttpResponse<void>> {
    const apiURL = `${this._serviceUrl}/swimmers/swimmer/${id}/update`;
    const payload = {
      isActive: isActive ? 'true' : 'false',
    };
    this.logger.info('service url: ', apiURL);
    return this.http.post<void>(apiURL, payload, { observe: 'response' });
  }

  addTimes(id: string, payload: any[]): Observable<HttpResponse<void>> {
    const apiURL = `${this._serviceUrl}/times/swimmer/${id}/times`;
    this.logger.info('service url: ', apiURL);
    return this.http.post<void>(apiURL, payload, { observe: 'response' });
  }

  removeTime(swimmerId: string, id: string): Observable<HttpResponse<void>> {
    const apiURL = `${this._serviceUrl}/times/swimmer/${swimmerId}/time/${id}/remove`;
    this.logger.info('service url: ', apiURL);
    return this.http.post<void>(apiURL, '', { observe: 'response' });
  }

  addTimesByStroke(payload: any[]): Observable<HttpResponse<void>> {
    const apiURL = `${this._serviceUrl}/times/swimmers`;
    this.logger.info('service url: ', apiURL);
    return this.http.post<void>(apiURL, payload, { observe: 'response' });
  }

  getSwimmerFastestTimesForStroke(
    swimmerId: string,
    stroke: string
  ): Observable<HttpResponse<Time[]>> {
    const apiURL = `${this._serviceUrl}/times/swimmer/${swimmerId}/${stroke}/fastest`;
    this.logger.info('service url: ', apiURL);
    return this.http.get<Time[]>(apiURL, { observe: 'response' });
  }

  getSwimmerFastestTimes(
    swimmerId: string,
    course: string = null
  ): Observable<HttpResponse<Time[]>> {
    const courseArg = course != null ? 'course=' + course : '';
    const apiURL = `${this._serviceUrl}/times/swimmer/${swimmerId}/fastest?${courseArg}`;
    this.logger.info('service url: ', apiURL);
    return this.http.get<Time[]>(apiURL, { observe: 'response' });
  }

  getStrokeScores(swimmerId: string): Observable<HttpResponse<StrokeScore[]>> {
    const apiURL = `${this._serviceUrl}/times/swimmer/${swimmerId}/fastest/ranks`;
    this.logger.info('service url: ', apiURL);
    return this.http.get<StrokeScore[]>(apiURL, { observe: 'response' });
  }

  getLatestTimes(
    team: string,
    keyword: string,
    nextSet: number
  ): Observable<HttpResponse<PagedResult<Time>>> {
    const apiURL = `${this._serviceUrl}/times/team/${team}/latest?keyword=${keyword}&nextSet=${nextSet}`;
    this.logger.info('service url: ', apiURL);
    return this.http.get<PagedResult<Time>>(apiURL, { observe: 'response' });
  }

  getTimeForStrokeDistance(
    swimmerId: string,
    stroke: string,
    distance: number,
    course: string,
    nextSet: number
  ): Observable<HttpResponse<PagedResult<Time>>> {
    const apiURL = `${this._serviceUrl}/times/swimmer/${swimmerId}/distance/${distance}/${stroke}/course/${course}?nextSet=${nextSet}`;
    this.logger.info('service url: ', apiURL);
    return this.http.get<PagedResult<Time>>(apiURL, { observe: 'response' });
  }

  getTimeStandardsForGender(
    state: string,
    year: number,
    gender: string,
    age: string
  ): Observable<HttpResponse<TimeStandard[]>> {
    const apiURL = `${this._serviceUrl}/times/state/${state}/year/${year}/gender/${gender}/${age}/standards`;
    this.logger.info('service url: ', apiURL);
    return this.http.get<TimeStandard[]>(apiURL, { observe: 'response' });
  }

  getTimeStandards(
    state: string,
    year: number
  ): Observable<HttpResponse<TimeStandard[]>> {
    const apiURL = `${this._serviceUrl}/times/state/${state}/year/${year}/standards`;
    this.logger.info('service url: ', apiURL);
    return this.http.get<TimeStandard[]>(apiURL, { observe: 'response' });
  }

  getHistory(swimmerId: string): Observable<HttpResponse<History[]>> {
    const apiURL = `${this._serviceUrl}/history/swimmer/${swimmerId}`;
    this.logger.info('service url: ', apiURL);
    return this.http.get<History[]>(apiURL, { observe: 'response' });
  }
}
