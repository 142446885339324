import { Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { ErrorLayoutComponent } from './layouts/error/error-layout.component';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./global/login/login.module').then(m => m.LoginModule),
      },
    ],
  },
  {
    path: 'error',
    component: ErrorLayoutComponent,
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./sections/dashboard/dashboard.module').then(
            m => m.DashboardModule
          ),
      },
      {
        path: 'settings',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./sections/settings/settings.module').then(
            m => m.SettingsModule
          ),
        data: {
          feature: 'settings',
        },
      },

      {
        path: 'team',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./sections/team/team.module').then(m => m.TeamModule),
        data: {
          feature: 'teams',
        },
      },
      {
        path: 'groups',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./sections/groups/groups.module').then(m => m.GroupsModule),
        data: {
          feature: 'groups',
        },
      },
      {
        path: 'coaches',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./sections/coaches/coaches.module').then(
            m => m.CoachesModule
          ),
        data: {
          feature: 'coaches',
        },
      },
      {
        path: 'times',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./sections/times/times.module').then(m => m.TimesModule),
        data: {
          feature: 'times',
        },
      },
      {
        path: 'swimmers',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./sections/swimmers/swimmers.module').then(
            m => m.SwimmersModule
          ),
      },
      {
        path: 'parents',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./sections/parents/parents.module').then(
            m => m.ParentsModule
          ),
        data: {
          feature: 'parents',
        },
      },

      {
        path: 'messaging',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./sections/messaging/messaging.module').then(
            m => m.MessagingModule
          ),
        data: {
          feature: 'messaging',
        },
      },
      {
        path: 'training',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./sections/training/training.module').then(
            m => m.TrainingModule
          ),
        data: {
          feature: 'training',
        },
      },
      {
        path: 'meets',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./sections/meets/meets.module').then(m => m.MeetsModule),
        data: {
          feature: 'meets',
        },
      },
      {
        path: 'merchandise',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./sections/merchandise/merchandise.module').then(
            m => m.MerchandiseModule
          ),
        data: {
          feature: 'merchandise',
        },
      },
      {
        path: 'schedule',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./sections/schedule/schedule.module').then(
            m => m.ScheduleModule
          ),
        data: {
          feature: 'schedule',
        },
      },
      {
        path: 'management',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./sections/management/management.module').then(
            m => m.ManagementModule
          ),
        data: {
          feature: 'management',
        },
      },
    ],
  },
];
