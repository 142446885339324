import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import {
  Notification,
  NotificationAlignment,
  NotificationPosition,
  NotificationTypes,
} from './shared/notification';
import { NGXLogger } from 'ngx-logger';
import { Router } from '@angular/router';
import { Insights } from './config/services/insights.service';
import { genUniqueId } from './shared/utilities';
import { IExceptionTelemetry } from '@microsoft/applicationinsights-web';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private logger: NGXLogger,
    private insights: Insights,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      map(res => {
        return res;
      }),
      catchError((error: HttpErrorResponse) => {
        const response = error.error;
        let errorMsg = '';
        if (error.error instanceof ErrorEvent) {
          this.logger.error('Error (client)', error.error.message);
        } else {
          this.logger.error(
            'Error (server)',
            error.status,
            error.message,
            error.error
          );

          const exception: IExceptionTelemetry = {
            id: genUniqueId(),
            exception: {
              message: error.message,
              url: '',
              lineNumber: 0,
              columnNumber: 0,
              error: error.error,
            },
          };
          this.insights.trackException(exception);
          switch (error.status) {
            case 404:
              break;
            case 500:
            case 400:
            case 422:
              Notification.show(
                `An error has occurred.  ${response.message}`,
                NotificationTypes.danger,
                NotificationPosition.bottom,
                NotificationAlignment.right,
                10000
              );
              break;

            default:
              this.router.navigate(['/error']);
              break;
          }
        }

        return throwError('error');
      })
    );
  }
}
