import * as moment from 'moment';

export function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach(item => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export function toProperCase(txt: string) {
  if (!txt) return '';
  return txt.replace(/\w\S*/g, function (txt: string) {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
}

export function int2roman(original: number): string {
  if (original < 1 || original > 3999) {
    //throw new Error('Error: Input integer limited to 1 through 3,999');
    return '';
  }
  const numerals = [
    ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX'], // 1-9
    ['X', 'XX', 'XXX', 'XL', 'L', 'LX', 'LXX', 'LXXX', 'XC'], // 10-90
    ['C', 'CC', 'CCC', 'CD', 'D', 'DC', 'DCC', 'DCCC', 'CM'], // 100-900
    ['M', 'MM', 'MMM'], // 1000-3000
  ];

  // TODO: Could expand to support fractions, simply rounding for now
  const digits = Math.round(original).toString().split('');
  let position = digits.length - 1;

  return digits.reduce((roman, digit) => {
    if (digit !== '0') {
      roman += numerals[position][parseInt(digit, 10) - 1];
    }

    position -= 1;

    return roman;
  }, '');
}

export function changeTimeZone(date, timeZone) {
  if (typeof date === 'string') {
    return new Date(
      new Date(date).toLocaleString('en-US', {
        timeZone,
      })
    );
  }

  return new Date(
    date.toLocaleString('en-US', {
      timeZone,
    })
  );
}

export function toCentisecond(date1: Date): number {
  //
  const minutes = date1.getMinutes();
  const seconds = date1.getSeconds();
  const miliseconds = date1.getMilliseconds();

  const totalSeconds = minutes * 60 + seconds + miliseconds / 1000;
  const totalCentiseconds = totalSeconds * 100;
  return totalCentiseconds;
}
export function toCentisecondFromString(time: string): number {
  if (time === '0') return 0;
  if ((time.match(/:/g) || []).length === 0) {
    time = '00:00:' + time;
  } else if ((time.match(/:/g) || []).length === 1) {
    time = '00:' + time;
  }
  const timeParsed = moment(time, 'HH:mm:ss.SSS');
  //console.info('toCentisecond', Math.round(toCentisecond(timeParsed.toDate())));
  return Math.round(toCentisecond(timeParsed.toDate()));
}

export function fromCentisecond(time: number): string {
  let milliseconds: number = (time / 100) * 1000;
  const ms = milliseconds % 1000;
  milliseconds = (milliseconds - ms) / 1000;
  const secs = milliseconds % 60;
  milliseconds = (milliseconds - secs) / 60;
  const mins = milliseconds % 60;
  return pad(mins) + ':' + pad(secs) + '.' + pad(ms, 3);
}
export function pad(n: number, z = 2) {
  z = z || 2;
  return ('00' + n).slice(-z);
}

export function getAgeGroup(
  min: number,
  max: number,
  condensed: boolean = false
) {
  let s = '';

  const under = condensed ? ' + U' : ' and under';
  const over = condensed ? ' + O' : ' and over';

  if (min <= 0 && max < 25) {
    s = max.toString() + under;
  } else if (min > 0 && max > 100) {
    s = min.toString() + over;
  } else if (min > 0 && max < 25) {
    s = min.toString() + ' - ' + max.toString();
  } else if (min <= 0 && max > 25) {
    s = 'Open';
  }
  return s;
}

export function getTimeCode(code: string) {
  if (!code || code == '') return '';

  switch (code.toLowerCase()) {
    case 'no_show':
      return 'NS';
    case 'disqualification':
      return 'D';
    case 'scratch':
      return 'S';
    case 'false_start':
      return 'F';
    case 'did_not_finish':
      return 'D';
    case 'unknown':
      return 'U';
    case 'normal':
      return '';
    default:
      return '';
  }
}
export function calcDifference(seed: number, final: number) {
  if (seed <= 0 || final <= 0) return 0;

  const seedCenti = seed * 100;
  let result = 0;
  if (seedCenti > final) {
    result = -((seedCenti - final) / 100);
  } else {
    result = (final - seedCenti) / 100;
  }
  //console.info(result)
  return result;
}

export function genUniqueId(): string {
  const dateStr = Date.now().toString(36); // convert num to base 36 and stringify

  const randomStr = Math.random().toString(36).substring(2, 8); // start at index 2 to skip decimal point

  return `${dateStr}-${randomStr}`;
}
