import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root' // This makes it a singleton service across the app
})
export class EventsService {
  private eventSubject = new Subject<any>();

  // Observable for components to subscribe to
  event$ = this.eventSubject.asObservable();

  // Method to publish an event
  publish(payload: any) {
    this.eventSubject.next(payload);
  }
}
