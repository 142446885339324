import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { DomSanitizer } from '@angular/platform-browser';
import { Profile } from '../../config/interfaces/profile.interface';
import { FeatureFlagsService } from 'src/app/config/services/feature-flags.service';
import { Scopes } from '../../config/enums/scopes.enum';
import { CachedService } from '../../config/services/cached.service';
import { Team } from '../../config/interfaces/team.interface';
import { EventsService } from '../../config/services/events.service';

declare const $: any;
//export const profile: Profile = JSON.parse(localStorage.getItem('profile'));

//Metadata
export interface RouteInfo {
  path: string;
  title: string;
  isBeta?: boolean;
  type: string;
  flag?: string;
  icontype: string;
  collapse?: string;
  children?: ChildrenItems[];
  permission?: Scopes;
}

export interface ChildrenItems {
  path: string;
  flag?: string;
  title: string;
  pageTitle: string;
  ab?: string;
  icontype?: string;
  type?: string;
  permission?: Scopes;
}
//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    flag: 'dashboard',
    title: 'Dashboard',
    type: 'link',
    icontype: 'dashboard',
  },
  {
    path: '/team',
    flag: 'team',
    title: 'Team',
    type: 'sub',
    collapse: 'team',
    icontype: 'groups',
    permission: Scopes.manageTeam,
    children: [
      {
        path: '',
        pageTitle: 'Team',
        title: 'Edit',
        icontype: 'edit_note',
      }
     
    ],
  },
  {
    path: '/groups',
    flag: 'groups',
    title: 'Groups',
    type: 'sub',
    collapse: 'groups',
    icontype: 'people',
    children: [
      {
        path: '',
        pageTitle: 'Search',
        title: 'Search',
        icontype: 'manage_search',
      },
      {
        path: 'group/add',
        pageTitle: 'Add Group',
        title: 'Add Group',
        icontype: 'group_add',
      },
    ],
  },
  {
    path: '/times',
    flag: 'times',
    title: 'Times',
    type: 'sub',
    icontype: 'timer',
    collapse: 'times',
    children: [
      {
        path: '',
        pageTitle: 'Search',
        title: 'Search',
        icontype: 'manage_search',
      },
      {
        path: 'add',
        pageTitle: 'Add Times',
        title: 'Add Times',
        icontype: 'timer',
      },
    ],
  },

  {
    path: '/meets',
    flag: 'meets',
    isBeta: true,
    title: 'Meets',
    type: 'sub',
    icontype: 'stadium',
    collapse: 'meets',
    children: [
      {
        path: '',
        pageTitle: 'Meets Search',
        title: 'Search',
        icontype: 'manage_search',
      },
    ],
  },
  {
    path: '/coaches',
    flag: 'coaches',
    title: 'Coaches',
    type: 'sub',
    icontype: 'sports',
    collapse: 'coaches',
    children: [
      {
        path: '',
        pageTitle: 'Coaches Search',
        title: 'Search',
        icontype: 'manage_search',
      },
      {
        path: 'coach/add',
        pageTitle: 'Add New Coach',
        title: 'Add Coach',
        icontype: 'person_add',
        permission: Scopes.manageCoaches,
      },
    ],
  },
  {
    path: '/swimmers',
    flag: 'swimmers',
    title: 'Swimmers',
    type: 'sub',
    icontype: 'pool',
    collapse: 'swimmers',
    children: [
      {
        path: '',
        pageTitle: 'Search',
        title: 'Search',
        icontype: 'manage_search',
      },
      {
        path: 'swimmer/add',
        pageTitle: 'Add New Swimmer',
        title: 'Add Swimmer',
        icontype: 'person_add',
      },
    ],
  },

  {
    path: '/parents',
    flag: 'parents',
    title: 'Parents',
    type: 'sub',
    icontype: 'family_restroom',
    collapse: 'parents',
    children: [
      {
        path: '',
        pageTitle: 'Parents Search',
        title: 'Search',
        icontype: 'manage_search',
      },
      {
        path: 'parent/add',
        pageTitle: 'Add Parent',
        title: 'Add Parent',
        icontype: 'person_add',
      },
    ],
  },

  {
    path: '/challenges',
    flag: 'challenges',
    title: 'Challenges',
    type: 'link',
    icontype: 'emoji_events',
  },
  {
    path: '/messaging',
    flag: 'messaging',
    title: 'Messaging',
    type: 'sub',
    icontype: 'contact_mail',
    collapse: 'messaging',
    children: [
      {
        path: 'jobs',
        pageTitle: 'Messaging Jobs',
        title: 'Manage Jobs',
        icontype: 'manage_search',
      },
      {
        path: 'jobs/job/add',
        pageTitle: 'Send Message',
        title: 'Send Messages',
        icontype: 'local_post_office',
      },
      {
        path: 'quick/send',
        pageTitle: 'Send',
        title: 'Quick Send',
        icontype: 'sms',
      },
    ],
  },
  {
    path: '/messaging/lists',
    flag: 'lists',
    title: 'Lists',
    type: 'sub',
    icontype: 'contacts',
    collapse: 'lists',
    children: [
      {
        path: '',
        pageTitle: 'Lists',
        title: 'Manage Lists',
        icontype: 'list_alt',
      },
      {
        path: 'list/add',
        pageTitle: 'Add List',
        title: 'Add List',
        icontype: 'note_add',
      },
    ],
  },
  {
    path: '/training',
    flag: 'training',
    title: 'Training',
    type: 'sub',
    icontype: 'pending_actions',
    collapse: 'training',
    children: [
      {
        path: 'workouts',
        flag: 'workouts',
        pageTitle: 'Workout Search',
        title: 'Workouts',
        icontype: 'manage_search',
      },
      {
        path: 'drills',
        flag: 'drills',
        pageTitle: 'Drills Search',
        title: 'Drills',
        icontype: 'manage_search',
      },
      {
        path: 'drills/drill/add',
        pageTitle: 'Add Drill',
        title: 'Add Drill',
        icontype: 'note_add',
      },
    ],
  },
  {
    path: '/schedule',
    flag: 'schedule',
    title: 'Schedule',
    type: 'link',
    icontype: 'calendar_month',
    collapse: 'schedule',
  },
  {
    path: '/management',
    flag: 'management',
    title: 'Management',
    type: 'sub',
    icontype: 'settings',
    collapse: 'management',
    permission: Scopes.admin,
    children: [
      {
        path: 'teams',
        pageTitle: 'Teams',
        title: 'Teams',
        icontype: 'manage_search',
        permission: Scopes.admin,
      },
      {
        path: 'imports',
        pageTitle: 'Imports',
        title: 'Imports',
        icontype: 'cloud_upload',
        permission: Scopes.admin,
      },
      {
        path: 'profiles/profile/add',
        pageTitle: 'Add Profile',
        title: 'Add Profile',
        icontype: 'person_add',
        permission: Scopes.admin,
      },
      {
        path: 'profiles',
        pageTitle: 'Profiles',
        title: 'Profiles',
        icontype: 'admin_panel_settings',
        permission: Scopes.admin,
      },
    ],
  },
];
@Component({
  selector: 'app-sidebar-cmp',
  templateUrl: 'sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  constructor(
    public sanitized: DomSanitizer,
    public features: FeatureFlagsService,
    private cachedService: CachedService,
    private eventsService: EventsService
  ) {}
  public menuItems: RouteInfo[];
  ps: any;

  p:Profile;

  teams:Team[] = [];
  teamLogo: string;

  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }


  ngOnInit() {
    this.eventsService.event$.subscribe((event) => {
      console.info('event', event);
      if (event.type === 'impersonate_team') {
        this.p.team = event.payload.team;
        localStorage.setItem('profile', JSON.stringify(this.p));
      }
    });

    this.p = JSON.parse(localStorage.getItem('profile'))
    console.info('profile', this.p);

    this.cachedService.teams$.subscribe(teams => {
      this.teams = teams;

      const team = this.teams.find(team => team.code === this.p.team.code);
      this.teamLogo = team.logo;

    });

    this.menuItems = ROUTES.filter(menuItem => menuItem);
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = <HTMLElement>(
        document.querySelector('.sidebar .sidebar-wrapper')
      );
      this.ps = new PerfectScrollbar(elemSidebar);
    }
  }
  sanitize(text: string) {
    return this.sanitized.bypassSecurityTrustHtml(text);
  }
  updatePS(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      this.ps.update();
    }
  }
  isMac(): boolean {
    let bool = false;
    if (
      navigator.platform.toUpperCase().indexOf('MAC') >= 0 ||
      navigator.platform.toUpperCase().indexOf('IPAD') >= 0
    ) {
      bool = true;
    }
    return bool;
  }
  hasPermission(permission?: Scopes) {
    if (permission == null || this.p.role.endsWith('admin')) {
      return true;
    }
    if (this.p.permissions.includes(permission.toString())) {
      return true;
    }
    return false;
  }
}
