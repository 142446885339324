export const environment = {
  production: true,
  serviceUrl: 'https://api-services.swimwarrior.com',
  meetServiceUrl: 'https://api-services.swimwarrior.com',

  googleKey: 'AIzaSyDv_zASYR48_J_hVYuW8eL8xETJg5kSCqA',
  featureConfig: `https://stswimwarrior.blob.core.windows.net/settings/features.json`,
  applicationInsightsConnection:
    'InstrumentationKey=137dfe7d-0615-4e97-99bc-3de9763238b2;IngestionEndpoint=https://westus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://westus.livediagnostics.monitor.azure.com/',
  applicationInsightsKey: '137dfe7d-0615-4e97-99bc-3de9763238b2',
  pusherAppId: '1553580',
  pusherKey: '37080ec0eda1c3216f5b',
  pusherSecret: '1780d6901961fb492cbb',
  pusherCluster: 'us3',


  googleApiKey: 'AIzaSyDv_zASYR48_J_hVYuW8eL8xETJg5kSCqA',
};
