import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { ErrorLayoutComponent } from './layouts/error/error-layout.component';
import { MatNativeDateModule } from '@angular/material/core';
import { SharedModule } from './shared/shared.module';
import { AppRoutes } from './app.routing';

import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { environment } from '../environments/environment';

import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { BreadcrumbService } from 'xng-breadcrumb';
import { HttpErrorInterceptor } from './http-error-iterceptor';
import { ExportService } from './config/services/export.service';
import { FeatureFlagsService } from './config/services/feature-flags.service';
import { FeatureDirective } from './config/directives/feature.directive';

import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { Insights } from './config/services/insights.service';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { PusherService } from './config/services/pusher.service';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

const featureFactory = (featureFlagsService: FeatureFlagsService) => () =>
  featureFlagsService.loadConfig();

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    ErrorLayoutComponent,
    FeatureDirective,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    RouterModule.forRoot(AppRoutes, {
      enableTracing: true,
      useHash: true,
      onSameUrlNavigation: 'reload',
    }),
    HttpClientModule,
    LoggerModule.forRoot({
      level: NgxLoggerLevel.INFO,
      serverLogLevel: NgxLoggerLevel.ERROR,
    }),
    SharedModule,
    MaterialModule,
    BreadcrumbModule,
    AuthModule.forRoot({
      domain: 'swimwarrior.us.auth0.com',
      clientId: 'UuvEzq6QtlBzipmEgADhX8gjD7SYyb6P',
      //audience: 'services.swimwarrior.com',
      cacheLocation: 'localstorage',
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: 'services.swimwarrior.com',
      },
      httpInterceptor: {
        allowedList: [
          {
            uri: `${environment.serviceUrl}/*`,
            tokenOptions: {
              authorizationParams: {
                audience: 'services.swimwarrior.com',
              },
            },
          },
        ],
      },
    }),
  ],
  exports: [SharedModule, FeatureDirective, HttpClientModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: featureFactory,
      deps: [FeatureFlagsService],
      multi: true,
    },
    ExportService,
    MatNativeDateModule,
    BreadcrumbService,
    Insights,
    JwtHelperService,

    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService,
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    PusherService,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { floatLabel: 'always', appearance: 'outline' },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  /*
  constructor(
    private readonly router: Router,
  ) { 
    router.events.subscribe(console.log)
  }
*/
}
