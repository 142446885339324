import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { map, shareReplay } from 'rxjs';
import { SwimmerService } from './swimmer.service';
import { TeamService } from './team.service';

@Injectable({
  providedIn: 'root',
})
export class CachedService {
  private year = new Date().getFullYear();
  private profile = JSON.parse(localStorage.getItem('profile'));

  constructor(
    public serviceSwimmer: SwimmerService,
    public serviceTeam: TeamService,
    private logger: NGXLogger
  ) {}

  public standards$ = this.serviceSwimmer
    .getTimeStandards(this.profile.team.state, this.year)
    .pipe(
      map((value: any) => {
        this.logger.info(value.body);
        return value.body;
      }),
      shareReplay(1)
    );

  public teams$ = this.serviceTeam.getMemberTeams().pipe(
    map((value: any) => {
      this.logger.info(value.body);
      return value.body;
    }),
    shareReplay(1)
  );

  public groups$ = this.serviceTeam.getAllGroups(this.profile.team.code).pipe(
    map((value: any) => {
      this.logger.info(value.body);
      return value.body;
    }),
    shareReplay(1)
  );
}
