import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FixedpluginComponent } from './fixedplugin.component';

@NgModule({
  imports: [CommonModule],
  declarations: [FixedpluginComponent],
  exports: [FixedpluginComponent],
})
export class FixedpluginModule {}
