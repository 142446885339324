export enum Scopes {
  manageSwimmers = 'manage.swimmers',
  manageCoaches = 'manage.coaches',
  manageSchedule = 'manage.schedule',
  manageTraining = 'manage.training',
  manageChallenges = 'manage.challenges',
  manageMeets = 'manage.meets',
  manageTeam = 'manage.team',
  readResults = 'read.results',
  createMeets = 'create.meets',
  createMessages = 'create.messages',
  importSwimmers = 'import.swimmers',
  importTimes = 'import.times',
  importMeets = 'import.meets',
  createTimes = 'create.times',
  admin = 'admin',
}
