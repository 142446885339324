<nav
  class="navbar navbar-expand-lg bg-primary navbar-transparent navbar-absolute"
  color-on-scroll="500">
  <div class="container">
    <div class="navbar-wrapper">
      <a
        class="navbar-brand d-none d-sm-none d-md-block"
        [routerLink]="['/login']">
        Swim Warrior Administrative Portal</a
      >
    </div>
    <button
      mat-button
      class="navbar-toggler"
      type="button"
      (click)="sidebarToggle()">
      <span class="sr-only">Toggle navigation</span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end">
      <ul class="navbar-nav">
        <li class="nav-item" routerLinkActive="active">
          <ng-container
            *ngIf="(auth.isAuthenticated$ | async) === true; else loggedOut">
            <a class="nav-link" href="#" (click)="onLogout()">
              <i class="material-icons">fingerprint</i> Logout
            </a>
          </ng-container>
          <ng-template #loggedOut>
            <a class="nav-link" href="#" (click)="onLogin()">
              <i class="material-icons">fingerprint</i> Login
            </a>
          </ng-template>
        </li>
      </ul>
    </div>
  </div>
</nav>
<router-outlet></router-outlet>
