import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  public search$: EventEmitter<string>;

  constructor() {
    this.search$ = new EventEmitter();
  }

  public broadcast(keyword: string): void {
    this.search$.emit(keyword);
  }
}
