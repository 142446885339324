<nav
  class="navbar navbar-expand-lg bg-primary navbar-transparent navbar-absolute"
  color-on-scroll="500">
  <div class="container">
    <div class="navbar-wrapper">
      <a
        class="navbar-brand d-none d-sm-none d-md-block"
        [routerLink]="['/dashboard']">
        Swim Warrior Administrative Portal</a
      >
    </div>
    <button mat-button class="navbar-toggler" type="button">
      <span class="sr-only">Toggle navigation</span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end">
      <ul class="navbar-nav">
        <li class="nav-item" routerLinkActive="active"></li>
      </ul>
    </div>
  </div>
</nav>
<div class="wrapper wrapper-full-page">
  <div
    class="page-header login-page header-filter"
    filter-color="black"
    style="
      background-image: url('./assets/img/dog_background.jpg');
      background-size: cover;
      background-position: top center;
    ">
    <div class="container">
      <div class="row">
        <div class="col-md-6 ml-auto mr-auto">
          <div class="card card-error">
            <h1>what did you do?</h1>
            <h2>
              Congratulations you broke the internet! Just kidding, something
              isn't right! Rest assured our team just hopped out of the pool to
              investigate!
            </h2>
          </div>
        </div>
      </div>
    </div>
    <footer class="footer">
      <div class="container">
        <nav class="pull-left">
          <ul>
            <li>
              <a href="http://swimwarrior.com/about-us"> About Us </a>
            </li>
            <li>
              <a href="http://swimwarrior.com/clinics"> Clinics </a>
            </li>
            <li>
              <a href="https://swimwarrior/license"> Licenses </a>
            </li>
          </ul>
        </nav>
        <div class="copyright pull-right">
          &copy;{{ current | date : 'yyyy' }}, Swim Warrior LLC
        </div>
      </div>
    </footer>
  </div>
</div>
