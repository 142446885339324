import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar.component';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { MaterialModule } from '../../material.module';
import { SharedModule } from '../shared.module';
import { AlertsComponent } from './alerts/alerts.component';
@NgModule({
  imports: [RouterModule, CommonModule, BreadcrumbModule, MaterialModule],
  declarations: [NavbarComponent, AlertsComponent],
  exports: [NavbarComponent],
})
export class NavbarModule {}
